import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet';

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <style type="text/css">
        {`
        nav.main ul li a {color: var(--black);
      `}
      </style>
    </Helmet>
    <SEO title="404: Not found" />
    <div id="notfound" class="grid-container">
        <div class="grid-x align-center align-middle">
		    <div class="cell small-11 medium-6 text-center">
		    	<h1>NOT FOUND</h1>
    			<p>We can't find that page.</p>
			</div>
    	</div>
    </div>
  </Layout>
)

export default NotFoundPage

